@use '@shared/util-styling/mixins';

.profile-photo-container {
  $_badge-size-small: 64px;
  $_badge-size: 80px;

  height: fit-content;
  position: relative;
  width: fit-content;

  &__button {
    @include mixins.button-reset;

    align-items: center;
    background-color: var(--mdrt-color-backgrounds-bg-surface-03);
    border-radius: 50%;
    display: flex;
    justify-content: center;
  }

  &__badge {
    align-items: center;
    border-radius: 50%;
    bottom: 0;
    display: flex;
    height: $_badge-size-small;
    justify-content: center;
    position: absolute;
    right: 0;
    width: $_badge-size-small;
  }

  @include mixins.breakpoint('medium') {
    &__badge {
      height: $_badge-size;
      width: $_badge-size;
    }
  }
}
