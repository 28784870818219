@use '@shared/util-styling/mixins';

$_color-overlay-text: rgb(255 255 255 / 80%);
$_color-overlay-background: rgb(28 28 28 / 50.2%);
$_transition-opacity: opacity 0.3s ease;
$_photo-container-size: 224px;
$_photo-container-size-small: 172px;

.profile-photo {
  $_this: &;

  align-items: center;
  background-color: var(--mdrt-color-backgrounds-bg-surface-03);
  border-radius: 50%;
  box-sizing: content-box;
  display: flex;
  height: $_photo-container-size-small;
  justify-content: center;
  min-height: $_photo-container-size-small;
  min-width: $_photo-container-size-small;
  object-fit: cover;
  overflow: hidden;
  position: relative;
  width: $_photo-container-size-small;

  @include mixins.breakpoint('medium') {
    height: $_photo-container-size;
    min-height: $_photo-container-size;
    min-width: $_photo-container-size;
    width: $_photo-container-size;
  }

  &__image {
    display: block;
    width: 100%;
  }

  &__icon {
    color: var(--mdrt-color-text-on-light-placeholder);
    height: 32px;

    @include mixins.breakpoint('medium') {
      height: 38px;
    }
  }

  &__content {
    align-items: center;
    border-radius: inherit;
    color: var(--mdrt-color-text-on-light-tertiary);
    display: flex;
    flex-direction: column;
    font: var(--mdrt-title-4);
    gap: var(--spacing-4);
    justify-content: center;

    &--hover {
      background-color: $_color-overlay-background;
      color: $_color-overlay-text;
      height: 100%;
      opacity: 0;
      position: absolute;
      transition: $_transition-opacity;
      width: 100%;

      &:hover {
        opacity: 1;
      }

      #{$_this}__icon {
        color: $_color-overlay-text;
      }
    }
  }

  &--is-fluid {
    height: 100%;
    min-height: 0;
    min-width: 0;
    width: 100%;
  }

  &--has-border {
    border: var(--mdrt-border-thickness-03) solid var(--mdrt-color-borders-light-01);
  }
}
